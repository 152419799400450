import { Heading } from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import React from "react"
import ContactForm from "../components/contactForm"
import Layout from "../components/layout/layout"

export default function DataDeletionRequest({
  location,
}: {
  location: WindowLocation
}) {
  return (
    <Layout title="Data Deletion Request" location={location}>
      <div className="page">
        <Heading as="h1">Data Deletion Request</Heading>

        <ContactForm />
      </div>
    </Layout>
  )
}
